import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ContextProvider } from "../pages/context/ContextProvider";
import useAuth from "../hooks/useAuth";
import Cookies from "js-cookie";


const PrivateRoute = ({ exact, component: Component, ...rest }) => {

  const { auth } = useAuth();


    return (<>
    <Route
      exact={!!exact}
      rest
      render={(props) =>
        auth?.user ? (
          <Component {...props} {...rest}></Component>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
        )
      }
    ></Route>
  </>)
};

export default PrivateRoute;
