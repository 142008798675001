import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import Homepage from "../pages/Homepage";

import Faq from "../pages/others/Faq";
import Regular from "../pages/others/Regular-1";
import Terms from "../pages/others/Terms";


import User from "../pages/app/user/User";

import Account from "../pages/app/account/account";
import Azure from "../pages/app/server/azure";
import Campaign from "../pages/app/campaign/campaign";
import Report from "../pages/app/report/report";
import Offer from "../pages/app/offer/offers";
import DetaillesOffer from "../pages/app/offer/DetaillesOffer";
import Group from "../pages/app/group/group";
import Activity from "../pages/app/activity/activity";
import Terminal from "../pages/app/terminal/terminal";
import Linode from "../pages/app/server/linode";
import Gsuit from "../pages/app/activity/gsuit";
import AzureStorage from "../pages/app/storage/azure";
import Amazon from "../pages/app/storage/amazon";


const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}

        {/*Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/pages/regular`} component={Regular}></Route>

        {/*Application*/}


           <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/account`}
          render={() => ( <Account />)}>

          </Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/azure/server`}
          render={() => ( <Azure />)}>

          </Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/azure/linode`}
          render={() => ( <Linode />)}>

          </Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/azure/storage`}
          render={() => ( <AzureStorage />)}>

          </Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/amazon/storage`}
          render={() => ( <Amazon />)}>

          </Route>

          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/campaigns/`}
          render={() => ( <Campaign />)}>

          </Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/reports/`}
          render={() => ( <Report />)}>

          </Route>
          <Route exact path={`${process.env.PUBLIC_URL}/offers/:id/:account/:type`} component={DetaillesOffer}></Route>

          <Route //Context Api added
              exact
              path={`${process.env.PUBLIC_URL}/offers/`}
              render={() => ( <Offer />)}>
          </Route>

          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user`}
          render={() => (
              <User />
          )}
        ></Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/terminal`}
          render={() => (
              <Terminal />
          )}
        ></Route>
          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/activity`}
          render={() => (
              <Activity />
          )}
        ></Route>
       <Route //Context Api added
                exact
                path={`${process.env.PUBLIC_URL}/activity/smtp`}
                render={() => (
                    <Gsuit />
                )}
              ></Route>

          <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/group`}
          render={() => (
              <Group />
          )}
        ></Route>

        {/*Components*/}

        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
