import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
//url for production
export const urlBackend="http://95.217.17.166/backend"
export var url = "";
if (process.env.NODE_ENV === "development") {
    url = "";
} else {
    url = window.location.host.split("/")[1];
    if (url) {
        url = `/${window.location.host.split("/")[1]}`;
    } else url = process.env.PUBLIC_URL;
}



//Function to validate and return errors for a form
export const checkForm = (formData) => {
    let errorState = {};
    Object.keys(formData).forEach((item) => {
        if (formData[item] === null || formData[item] === "") {
            errorState[item] = "This field is required";
        }
    });
    return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
    let extractedString = [];

    for (var i = 0; i < string.length; i++) {
        if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
            extractedString.push(string.charAt(i));
        }
    }
    if (extractedString.length > 1) {
        return extractedString[0] + extractedString[1];
    } else {
        return extractedString[0];
    }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
    let todayDate = new Date();
    var newDate = new Date(todayDate);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
    let d = date.getDate();
    let m = date.getMonth();
    let y = date.getFullYear();
    let final = monthNames[m] + " " + d + ", " + y;
    return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
    let d = rdate.getDate();
    d < 10 && (d = "0" + d);
    let m = rdate.getMonth() + 1;
    m < 10 && (m = "0" + m);
    let y = rdate.getFullYear();
    rdate = y + "-" + m + "-" + d;

    return rdate;
};



// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
    var currentDate = new Date();
    var difference = deadline.getTime() - currentDate.getTime();
    var days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
};

//Date formatter function Example : 10-02-2004
export const dateFormatterAlt = (date, reverse) => {
    let d = date.getDate();
    let m = date.getMonth();
    let y = date.getFullYear();
    reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
    return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
    var dateformat = date.split("-");
    //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
    reverse
        ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
        : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

    return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
    var hours = todaysDate.getHours();
    var minutes = todaysDate.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
    let result = Number(str2) / Number(str1);
    result = result * 100;
    return Math.floor(result);
};

export const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

export const RedirectAs404 = ({ location }) => (
    <Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);

// returns upload url
export const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
    { value: "suspend", label: "Suspendre" },
    { value: "delete", label: "Supprimer " },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
    let result = bytes / (1024 * 1024);
    return result.toFixed(2);
};

export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];


export const notification = (type,message,id)=>{
    /*
     type     : success|info|warning|danger
     id       : Id of notification
     Message  : string
     */


    if (!id)
        return toast(message, {
            type: type,
            position: "bottom-right",
            autoClose:type !== 'info',
            hideProgressBar: false,
        });
    else
        toast.update(id, {render: message, type: type, isLoading: false, autoClose: true,      hideProgressBar: true });
}
export const  convertDateFormat=(dateString) =>{
    //const formattedDate = moment(dateString).format('DD-MM-YY h:mm');
    //return formattedDate;
}
export const ValidationMessageError = (error)=>{
    Object.keys(error.data).forEach((field) => {
        error.data[field].forEach((message) => {
            notification('error', message)
        });
    });
}

export const doesValueExist = (array, targetValue) => {
    return array.some(item => item.value === targetValue);
}
export const location = [
    { value:"italynorth" , label: "italynorth" },
    { value:"israelcentral" , label: "israelcentral" },
    { value:"centralus" , label: "centralus" },
    { value:"swedencentral" , label: "swedencentral" },
    { value:"polandcentral" , label: "polandcentral" },
    { value:"westus3" , label: "westus3" },
    { value:"qatarcentral" , label: "qatarcentral" },
    { value: "eastasia",label: "eastasia" },
    { value: "southeastasia", label: "southeastasia" },
    { value: "eastus", label: "eastus" },
    { value: "eastus2", label: "eastus2" },
    { value:"westus", label: "westus" },
    { value:"westus2", label: "westus2" },
    { value: "northcentralus", label: "northcentralus" },
    { value:"southcentralus", label: "southcentralus" },
    // { value: "westcentralus", label: "westcentralus" },
    { value:"northeurope", label: "northeurope" },
    { value:"japaneast", label: "japaneast" },
    { value:  "japanwest", label: "japanwest" },
    { value:"brazilsouth", label: "brazilsouth" },
    { value: "australiasoutheast", label: "australiasoutheast" },
    { value: "australiaeast", label: "australiaeast" },
    { value: "southindia", label: "southindia" },
    { value:"centralindia", label: "centralindia" },
    { value: "canadacentral", label: "canadacentral" },
    { value: "westeurope", label: "westeurope" },
    { value: "canadaeast", label: "canadaeast" },
    { value:"uksouth", label: "uksouth" },
    { value: "ukwest", label: "ukwest" },
    { value: "koreacentral", label: "koreacentral" },
    { value: "koreasouth", label: "koreasouth" },
    { value: "francecentral", label: "francecentral" },
    { value: "southafricanorth", label: "southafricanorth" },
    { value: "uaenorth", label: "uaenorth" },
    { value: "australiacentral", label: "australiacentral" },
    { value: "switzerlandnorth", label: "switzerlandnorth" },
    { value:"germanywestcentral", label: "germanywestcentral" },
    { value: "norwayeast", label: "norwayeast" }
];

export const convertUnixTimestampToMMDDYY = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Zero-padding for single-digit days, months, hours, and minutes
    const mm = month < 10 ? `0${month}` : month;
    const dd = day < 10 ? `0${day}` : day;
    const hh = hours < 10 ? `0${hours}` : hours;
    const mmPart = minutes < 10 ? `0${minutes}` : minutes;

    return `${mm}-${dd}-${year} ${hh}:${mmPart}`;
}

export const  convertDateTimeToMMDDYYHHMM =(dateTimeString)  =>{
    const date = new Date(dateTimeString);
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Zero-padding for single-digit days, months, hours, and minutes
    const mm = month < 10 ? `0${month}` : month;
    const dd = day < 10 ? `0${day}` : day;
    const hh = hours < 10 ? `0${hours}` : hours;
    const mmPart = minutes < 10 ? `0${minutes}` : minutes;

    return `${mm}-${dd}-${year} ${hh}:${mmPart}`;
}

